import "./ProblemList.css";
import Problem from "./Problem/Problem";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function ProblemList(props) {
    const [posts, setPosts] = useState([]);
    const [pageToken, setPageToken] = useState("");
    const [listState, setListState] = useState(0);
    const [showSpinner, toggleSpinner] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const stateRef = useRef();
    const postStateRef = useRef();
    const location = useLocation();

    stateRef.current = pageToken;
    postStateRef.current = posts;

    const handleScroll = async () => {
        const bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

        const newPageToken = stateRef.current;
        if (!newPageToken) {
            toggleSpinner(false);
            return;
        }

        if (bottom && newPageToken) {
            toggleSpinner(true);
            let res;

            if (location.pathname === "/") {
                res = await fetch(
                    `${
                        process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                    }/posts/list?limit=50${"&nextPageToken=" + newPageToken}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                        // mode: "cors",
                        body: JSON.stringify({
                            includeIndustries: [],
                            excludeIndustries: [],
                            excludePosts: [],
                            user: props.user ? props.user.username : "",
                        }),
                    }
                );
            } else {
                const topic = location.pathname.replaceAll("/", "");
                const sort = searchParams.get("sort");
                res = await fetch(
                    `${
                        process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                    }/topics/${topic}/posts?limit=50${
                        pageToken ? "&nextPageToken=" + pageToken : ""
                    }${sort ? "&sort=" + sort : ""}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                        // mode: "cors",
                    }
                );
            }

            const json = await res.json();
            if (!json) return;

            const { nextPageToken, posts: newPosts } = json;

            if (newPosts) {
                const set = new Set();
                const filteredPosts = [
                    ...postStateRef.current,
                    ...newPosts,
                ].filter((post) => {
                    if (set.has(post._id)) return false;
                    set.add(post._id);
                    return true;
                });
                setPosts(filteredPosts);
                if (nextPageToken) {
                    setPageToken(nextPageToken);
                }
                toggleSpinner(false);
            }
        }
    };

    const fetchCateredPosts = async () => {
        let term = searchParams.get("q");
        let filters = searchParams.get("filter");

        if (!filters) {
            filters = "";
        }
        if (!term) {
            const res1 = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/list?limit=5${
                    pageToken ? "&nextPageToken=" + pageToken : ""
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                    body: JSON.stringify({
                        includeIndustries: [],
                        excludeIndustries: [],
                        excludePosts: [],
                        user: props.user ? props.user.username : "",
                    }),
                }
            );
            const newPosts = ((await res1.json()?.posts) || []).slice(0, 5);
            if (!newPosts) return;
            const newLimit = 50 - newPosts.length;

            const res2 = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/list?limit=${newLimit}${
                    pageToken ? "&nextPageToken=" + pageToken : ""
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                    body: JSON.stringify({
                        includeIndustries: [],
                        excludeIndustries: [],
                        excludePosts: newPosts.map((post) => post._id),
                    }),
                }
            );
            const posts = await res2.json();

            if (posts.nextPageToken) {
                setPageToken(posts.nextPageToken);
            }

            setPosts([...newPosts, ...posts.posts]);
        } else {
            const res = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/search?term=${term}&filters=${filters}`,
                {
                    credentials: "include",
                }
            );
            const json = await res.json();

            if (json && json.posts) {
                setPosts([...json.posts] || []);
                setPageToken(json.nextPageToken || "");
                toggleSpinner(false);
            }
        }
    };

    const fetchPostsByTopic = async () => {
        const sort = searchParams.get("sort");
        const topic = location.pathname.replaceAll("/", "");

        const res = await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/topics/${topic}/posts?limit=50${
                pageToken ? "&nextPageToken=" + pageToken : ""
            }${sort ? "&sort=" + sort : ""}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
            }
        );

        const posts = await res.json();

        if (posts.nextPageToken) {
            setPageToken(posts.nextPageToken);
        }

        setPosts(posts.posts);
        toggleSpinner(false);
    };

    const fetchPosts = async () => {
        if (location.pathname === "/") {
            fetchCateredPosts();
        } else {
            fetchPostsByTopic();
        }
    };

    const getTitle = () => {
        switch (listState) {
            case 0:
                return "For you";
            case 1:
                return "Trending";
            case 2:
                return "New";
            case 3:
                return "Top";
        }
    };

    useEffect(() => {
        fetchPosts();
        window.addEventListener("scroll", handleScroll);
        if (location.pathname !== "/" && listState === 0) {
            setListState(1);
        }
    }, [searchParams, props.user, location.pathname]);
    return (
        <div id="ProblemList" className="p-3">
            <h1 className="display-5">{getTitle()}</h1>
            <div className="sort-bar">
                <ul>
                    {location.pathname === "/" ? (
                        <li>
                            <Link
                                onClick={(e) => setListState(0)}
                                className={
                                    listState === 0 ? "active" : "bar-item"
                                }
                                to={location.pathname}
                            >
                                For you
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    <li>
                        <Link
                            onClick={(e) => setListState(1)}
                            className={listState === 1 ? "active" : "bar-item"}
                            to={location.pathname}
                        >
                            Trending
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={(e) => setListState(2)}
                            className={listState === 2 ? "active" : "bar-item"}
                            to={location.pathname + "?sort=createTime"}
                        >
                            New
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={(e) => setListState(3)}
                            className={listState === 3 ? "active" : "bar-item"}
                            to={location.pathname + "?sort=likes"}
                        >
                            Top
                        </Link>
                    </li>
                </ul>
            </div>
            {posts.map((post) => (
                <Problem
                    post={post}
                    key={post._id}
                    user={props.user}
                    fetchPosts={fetchPosts}
                />
            ))}
            <div
                className={
                    showSpinner
                        ? "spinner spinner-border text-primary"
                        : "d-none"
                }
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
            {!posts.length && !showSpinner ? (
                <h1 className="lead p-5">Oops! nothing found</h1>
            ) : (
                <></>
            )}
            <br></br>
            <br />
            <br />
        </div>
    );
}

export default ProblemList;
