import "./Comment.css";
import React, { useState, useEffect } from "react";
import Respond from "../Respond/Respond";
import UserCard from "../../UserCard/UserCard";

const Comment = (props) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Get Comments
    if (props.comments && props.comments.replies) {
      setComments(props.comments.replies);
    }
  }, [props.comments]);

  return (
    <div className="Comments">
      {comments.map((comment) => (
        <div className="Comment" key={comment._id}>
          <UserCard
            img={comment.author?.photo || "https://media.istockphoto.com/id/1311084168/photo/overjoyed-pretty-asian-woman-look-at-camera-with-sincere-laughter.jpg?s=1024x1024&w=is&k=20&c=QMQ46nig58psiPNy6ruC8FFSkF-jUSlh17vEOfMLbgI="}
            headline={comment.author?.headline}
            username={comment.author?.username}
            company={comment.author?.company}
          />
          <p className="comment-content">{comment.content}</p>
          {props.user ? (
            !props.showReplies[comment._id] ? (
              <button
                className="btn btn-primary"
                onClick={() =>
                  props.setReplies(() => {
                    const copyOfReplies = { ...props.showReplies };
                    copyOfReplies[comment._id] = true;
                    return copyOfReplies;
                  })
                }
              >
                Reply
              </button>
            ) : (
              <Respond
                user={props.user}
                parentId={comment._id}
                postId={comment.postID}
                fetchComments={props.fetchComments}
              />
            )
          ) : (
            <></>
          )}
          <div className="replies">
            {comment.replies && comment.replies.length ? (
              <div className="ml-2">
                <Comment
                  comments={comment}
                  user={props.user}
                  fetchComments={props.fetchComments}
                  showReplies={props.showReplies}
                  setReplies={props.setReplies}
                ></Comment>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Comment;
