import "./Post.css";
import React, { useState, useEffect } from "react";
import Comment from "./Comment/Comment";
import Carousel from "react-bootstrap/Carousel";
import Respond from "./Respond/Respond";
import UserCard from "../UserCard/UserCard";
import Solution from "./Solution/Solution";
import { useParams, useSearchParams, Link } from "react-router-dom";

const Post = (props) => {
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState(null);
    const [showReplies, setReplies] = useState({});
    const [solutions, setSolutions] = useState(null);
    const [next, setNext] = useState([]);
    const [postTopics, setPostTopics] = useState([]);

    const params = useParams();

    const getDate = (date) => {
        if(!date){
            return ""
        }

        const shortMonthName = new Intl.DateTimeFormat("en-US", { month: "short" }).format;
        date = new Date(date)
        const shortName = shortMonthName(date); // "Jul"

        return `${shortName} ${date.getDay() + 1}, ${date.getFullYear()}`
    }

    const fetchComments = async () => {
        await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/comments/post/` + params.postId,
            {
                credentials: "include",
                method: "GET",
            }
        )
            .then((response) => {
                if (response.status === 404) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                setComments(data);
            });
    };

    const fetchSolutions = async () => {
        await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/solutions/` + params.postId,
            {
                credentials: "include",
                method: "GET",
            }
        )
            .then((response) => {
                if (response.status === 404) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                setSolutions(data);
            });
    };

    const fetchNext = async () => {
        const res = await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/list?limit=5`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({
                    includeIndustries: [],
                    excludeIndustries: [],
                    excludePosts: [params.postId],
                    user: props.user ? props.user.username : "",
                }),
            }
        );

        const json = await res.json();
        const { posts } = json;
        setNext(posts);
    };

    useEffect(() => {
        // Get Post
        if (!post || post._id !== params.postId) {
            window.scrollTo({ top: 0, behavior: "instant" })
            setComments(null)
            setNext([])
            fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/` + params.postId,
                {
                    credentials: "include",
                }
            )
                .then((response) => {
                    if (response.status === 404) {
                        return;
                    }
                    return response.json();
                })
                .then((data) => {
                    setPost(data);
                    if (data.topics) {
                        props.setTopic(data.topics[0]);
                        setPostTopics(data.topics);
                    }
                    fetchComments();
                    fetchSolutions();
                    fetchNext();
                });
        }
    }, [params.postId]);

    return (
        <div className="Post">
            {post ? (
                <div className="p-3">
                    <div className="lead-wrapper">
                        <h1 className="post-title">{post.title}</h1>
                        <span className="post-time">{post.updateTime || post.createTime ? "Updated " + getDate(post.updateTime) || getDate(post.createTime) : "" }</span>
                        <p className="mt-2">
                            {postTopics && postTopics.length ? (
                                postTopics
                                    .filter((topic) => topic != null)
                                    .map((topic) => (
                                        <span className="post-solution-badge">
                                            {topic}
                                        </span>
                                    ))
                                    .slice(0, 3)
                            ) : (
                                <></>
                            )}
                        </p>
                        <br></br>
                        <UserCard
                            img={
                                props.author?.photo ||
                                "https://media.istockphoto.com/id/1311084168/photo/overjoyed-pretty-asian-woman-look-at-camera-with-sincere-laughter.jpg?s=1024x1024&w=is&k=20&c=QMQ46nig58psiPNy6ruC8FFSkF-jUSlh17vEOfMLbgI="
                            }
                            headline={
                                props.author?.headline ||
                                "Data Scientist @ Amazon"
                            }
                            username={post.author?.username}
                            company={post.author?.company}
                        />
                        <hr></hr>
                        <p
                            className="content-text"
                            dangerouslySetInnerHTML={{
                                __html: post.content,
                            }}
                        ></p>
                    </div>
                    <div>
                        {solutions && solutions.length ? (
                            <h1 className="display-3">Solutions</h1>
                        ) : (
                            <></>
                        )}
                        <div>
                            <Solution
                                postId={params.postId}
                                solutions={solutions}
                            ></Solution>
                        </div>
                    </div>
                    <h3 className="display-4">See More</h3>
                    <hr></hr>
                    <Carousel>
                        {next.map((post) => (
                            <Carousel.Item>
                                <img src="https://loremflickr.com/1200/400?lock=330954189045760"></img>
                                <Carousel.Caption>
                                    <h3>
                                        <Link
                                            to={`/post/${post._id}`}
                                            className="carousel-link"
                                        >
                                            {post.title}
                                        </Link>
                                    </h3>
                                    <p>by {post.author.username}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <div>
                        {comments ? (
                            <>
                                <hr></hr>
                                <h1 className="display-4">Comments</h1>
                            </>
                        ) : (
                            <></>
                        )}
                        <div>
                            {props.user ? (
                                <Respond
                                    postId={params.postId}
                                    fetchComments={fetchComments}
                                    user={props.user}
                                />
                            ) : (
                                <></>
                            )}
                            <Comment
                                fetchComments={fetchComments}
                                showReplies={showReplies}
                                setReplies={setReplies}
                                comments={comments}
                                user={props.user}
                            ></Comment>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="jumbotron text-left mt-5">
                    <h1 className="display-1 mt-5">Oops!</h1>
                    <p className="lead">That page doesn't seem to exist</p>
                </div>
            )}
        </div>
    );
};

export default Post;
