import "./NewPost.css";
import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

function NewPost(props) {
    const [title, setTitle] = useState("");
    const [industry, setIndustry] = useState("");
    const editorRef = useRef(null);

    const handleSubmit = async () => {
        console.log(props.user);
        const body = {
            title,
            industry,
            content: editorRef.current.getContent(),
            author: {
                _id: props.user._id,
                label: props.user.label || "",
                username: props.user.username,
                photo: props.user.photo,
                company: props.user.company,
                headline: props.user.headline
            },
        };

        if (props.parent) {
            body.parent = props.parent;
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"}/posts/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            // mode: "cors",
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("created!", data);
            });
    };

    return (
        <div className="NewPost p-3">
            <label htmlFor="title">Title</label>
            <input
                id="title"
                className="form-control mb-2"
                placeholder=""
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                required="true"
            />
            <label htmlFor="industry">Industry</label>
            <input
                id="industry"
                className="form-control mb-2"
                placeholder=""
                value={industry}
                onChange={(event) => setIndustry(event.target.value)}
                required="true"
            />
            <label htmlFor="content">Content</label>
            <Editor
                apiKey="oc9271j0e7y8g2l0hw9dfobeet2aw2m0xtgukc0byid5ius1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Start your story</p>"
                init={{
                    height: 500,
                    menubar: false,
                    plugins:
                        "mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker a11ychecker typography inlinecss",
                    toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
            <button
                className="btn btn-outline-primary m-1"
                onClick={handleSubmit}
            >
                Submit
            </button>
        </div>
    );
}

export default NewPost;
