import "./Respond.css";
import React, { useState, useEffect } from "react";

const Respond = (props) => {
    const [text, setText] = useState("");
    const handleSubmit = async () => {
        const body = {
            postID: props.postId,
            content: text,
            author: {
                _id: props.user._id,
                label: props.user.label || "",
                username: props.user.username,
                photo: props.user.photo,
                company: props.user.company,
                headline: props.user.headline,
            },
            createTime: new Date().toISOString(),
        };

        if (props.parentId) {
            body.parentID = props.parentId;
        }

        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/comments/post/${props.postId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(body),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                props.fetchComments();
            });
    };
    return (
        <div className="Respond">
            Comment as {props.user.username}
            <textarea
                className="form-control m-2"
                placeholder="What are your thoughts?"
                value={text}
                onChange={(e) => {
                    setText(e.target.value);
                }}
            ></textarea>
            <div className="btn-box">
                <button onClick={handleSubmit} className="btn btn-primary">
                    Submit
                </button>
            </div>
        </div>
    );
};

export default Respond;
