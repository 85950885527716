import "./Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import UserCard from "../UserCard/UserCard";

function Navbar(props) {
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const [filters, setFilters] = useState([]);
  const [showDropdown, setDropdown] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    document.cookie = "";
    props.setUser(null);
  };

  const modifyFilters = (e) => {
    if (e.target.checked) {
      setFilters([...filters, e.target.name]);
    } else {
      const copyOfFilters = [...filters];

      setFilters(copyOfFilters.filter((val) => val !== e.target.name));
    }
  };

  const setNavigate = () => {
    let endpoint = "/";
    if (term.length) {
      endpoint += `?q=${term}`;
    }
    if (term.length && filters.length) {
      endpoint += `&filter=${filters}`;
    }

    if (!term.length && filters.length) {
      endpoint += `?filter=${filters}`;
    }

    return endpoint;
  };

  const isActive = (topic) => props.topic === topic;

  const isTopic = (location) =>
    location &&
    location !== "register" &&
    location !== "login" &&
    !location.includes("post") &&
    !location.includes("user");

  const getAllChildren = (element) => {
    let elements = [element];

    for (const child of element.children) {
      elements = [...elements, ...getAllChildren(child)];
    }

    return elements;
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const elements = getAllChildren(document.querySelector("#search-bar"));
      if (elements.includes(e.target)) {
        setDropdown(true);
      } else {
        setDropdown(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log(location.pathname);
    const path = location.pathname.replaceAll("/", "");
    if (
      path !== "register" &&
      path !== "login" &&
      !path.includes("post") &&
      !path.includes("user")
    ) {
      props.setTopic(path);
    }
  }, [location.pathname]);

  return (
    <div className="Navbar p-3">
      <div className="row">
        <div className="col-md-3">
          <div className="d-inline-block logo-holder">
          {props.topic || isTopic(location.pathname.slice(1)) ? (
            <Link to={`/${props.topic}` || location.pathname}>
              <h1 className="d-inline-block mr-auto">Spark Nexus</h1>
              <span className="nav-topic">
                {props.topic || location.pathname.slice(1)}
              </span>
            </Link>
          ) : (
            <Link to="/">
              <h1 className="d-inline-block mr-auto">Spark Nexus</h1>
            </Link>
          )}
          </div>
        </div>
        <div className="col-md-7">
          <div id="search-bar">
            <input
              className={
                showDropdown
                  ? "form form-control d-inline-block search-bar w-100 drop-on"
                  : "form form-control d-inline-block search-bar w-100"
              }
              placeholder="Search for a particular problem"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            ></input>
            <div className="search-btn-box">
              <i
                className="fa-solid fa-magnifying-glass search-btn"
                onClick={() => navigate(setNavigate())}
              ></i>
            </div>
            <div className={showDropdown ? "search-bar-drop-down" : "d-none"}>
              <strong className="drop-down-title">Filters</strong>
              <br />
              <div id="filters">
                <div className="filter-column">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Animals & Pets"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Animals & Pets
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="AI"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      AI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Apparel/Fashion"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Apparel/Fashion
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Arts & Entertainment"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Arts & Entertainment
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Tech"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Tech
                    </label>
                  </div>
                </div>
                <div className="filter-column">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Legal Services"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox3">
                      Legal Services
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Medical"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox3">
                      Medical
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Health & Fitness"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Health & Fitness
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Sports"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Sports
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="Electronics"
                      className="checkbox"
                      onChange={(e) => modifyFilters(e)}
                    />
                    <label className="form-check-label" for="inlineCheckbox2">
                      Electronics
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2">
        </div>
      </div>
      <div className="nav-user">
      {props.user ? (
            <>
              <UserCard
                username={props.user?.username}
                headline={props.user?.headline}
                img={props.user?.photo}
              ></UserCard>
              <button onClick={handleLogout} className="btn btn-outline-danger">
                Sign Out
              </button>
              <Link to="/post/new" className="btn btn-outline-success">
                New Post
              </Link>
            </>
          ) : (
            <>
              <Link to="/login" className="btn btn-outline-primary sign-in-btn">
                Sign In
              </Link>
            </>
          )}
      </div>
      <div className="row nav-link-holder">
        <div
          className={
            isActive("")
              ? "nav-link-active nav-link-wrapper"
              : "nav-link-wrapper"
          }
        >
          <Link className="nav-link" to="/">
            Home
          </Link>
        </div>
        <div
          className={
            isActive("tech")
              ? "nav-link-active nav-link-wrapper"
              : "nav-link-wrapper"
          }
        >
          <Link className="nav-link" to="/tech">
            Tech
          </Link>
        </div>
        <div
          className={
            isActive("cars")
              ? "nav-link-active nav-link-wrapper"
              : "nav-link-wrapper"
          }
        >
          <Link className="nav-link" to="/cars">
            Cars
          </Link>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Navbar;
