export default {
  problems: (score) => {
    if (score < 5) {
      return [
        "Idea Generator, Bronze",
        `${5 - score} problem posts away from Silver`,
        5,
        "bronze",
      ];
    }
    if (score < 20) {
      return [
        "Idea Generator, Silver",
        `${20 - score} problem posts away from Gold`,
        20,
        "silver",
      ];
    }
    if (score < 50) {
      return [
        "Idea Generator, Gold",
        `${50 - score} problem posts away from Platinum`,
        50,
        "gold",
      ];
    }
    if (score < 100) {
      return [
        "Idea Generator, Platinum",
        `${100 - score} problem posts away from Diamond`,
        100,
        "platinum",
      ];
    }
    if (score < 200) {
      return [
        "Idea Generator, Diamond",
        `${200 - score} problem posts away from Visionary`,
        200,
        "diamond",
      ];
    }

    return [
      "Visionary",
      `Congratulations! You've reached the highest tier for problem posts`,
      0,
      "visionary",
    ];
  },

  solutions: (score) => {
    if (score < 1) {
      return [
        "Problem Solver, Bronze",
        `${1 - score} solution posts away from Silver`,
        1,
        "bronze",
      ];
    }
    if (score < 5) {
      return [
        "Problem Solver, Silver",
        `${5 - score} solution posts away from Gold`,
        5,
        "silver",
      ];
    }
    if (score < 10) {
      return [
        "Problem Solver, Gold",
        `${10 - score} solution posts away from Platinum`,
        10,
        "gold",
      ];
    }
    if (score < 20) {
      return [
        "Problem Solver, Platinum",
        `${20 - score} solution posts away from Diamond`,
        20,
        "platinum",
      ];
    }
    if (score < 30) {
      return [
        "Problem Solver, Diamond",
        `${30 - score} solution posts away from Innovator`,
        30,
        "diamond",
      ];
    }

    return [
      "Innovator",
      `Congratulations! You've reached the highest tier for problem posts`,
      0,
      "innovator",
    ];
  },
};
