import "./Solution.css";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import UserCard from "../../UserCard/UserCard";

const useTruncatedElement = ({ ref }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [ref]);

  return {
    isTruncated,
    isReadingMore,
    setIsReadingMore,
  };
};

const Solution = (props) => {
  const ref = React.useRef(null);
  const { isTruncated, isReadingMore, setIsReadingMore } = useTruncatedElement({
    ref,
  });
  const [solutions, setSolutions] = useState([]);
  useEffect(() => {
    // Get Solutions
    if (props.postId && props.solutions) {
      setSolutions(props.solutions);
    }
    console.log(solutions);
  }, [props.solutions]);

  return (
    <div className="Solutions">
      {solutions.map((solution) => (
        <div className="Solution">
          <Link
            className="display-6 solution-title"
            to={`/post/${solution._id}`}
          >
            {solution.title}
          </Link>
          <UserCard
            username={solution.author?.username}
            img={solution.author?.photo || "https://images.pexels.com/photos/5384445/pexels-photo-5384445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
            company={solution.author?.company}
            headline={solution.author?.headline || "CEO @Sail.io"}
          ></UserCard>
          <p>{solution.author?.name}</p>
          <p
            ref={ref}
            className={`break-words text-xl ${
              !isReadingMore && "line-clamp-3"
            }`}
          >
            {solution.content}
          </p>
          {isTruncated && !isReadingMore && (
            <button onClick={() => setIsReadingMore(true)}>Read more</button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Solution;
