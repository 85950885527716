import "./User.css";
import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import levels from "../../levels";
import CalendarHeatmap from "react-calendar-heatmap";
import Radar from "react-d3-radar/lib/Radar";
import ReactTooltip from "react-tooltip";

let today = new Date();

const User = (props) => {
    const params = useParams();
    const [user, setUser] = useState(null);

    const fetchUser = () => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"}/auth/${
                params.username
            }`,
            {
                credentials: "include",
            }
        )
            .then((response) => {
                if (response.status === 404) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                setUser(data);
            });
    };

    useEffect(() => {
        fetchUser();
    }, [params.username]);

    return (
        <div className="User">
            {user ? (
                <div className="row main-user-container">
                    <div className="left-user-rail">
                        <div className="user-details">
                            <div className="row">
                                <div className="col-md-12">
                                    <img
                                        src={
                                            user.photo || "https://images.pexels.com/photos/5792641/pexels-photo-5792641.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        }
                                    ></img>
                                </div>
                                <div className="col-md-12">
                                    <p className="user-info">
                                        <span className="user-label">
                                            {user.label}
                                        </span>{" "}
                                        {user.badges ? (
                                            user.badges.map((badge) => (
                                                <OverlayTrigger
                                                    delay={{
                                                        hide: 450,
                                                        show: 300,
                                                    }}
                                                    overlay={(props) => (
                                                        <Tooltip
                                                            placement="top"
                                                            {...props}
                                                        >
                                                            {badge.description}
                                                        </Tooltip>
                                                    )}
                                                >
                                                    <span
                                                        className={`badge-${badge.slug}`}
                                                    >
                                                        {badge.title}
                                                    </span>
                                                </OverlayTrigger>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        <br></br>
                                        <span className="user-headline"></span>
                                        {user.headline}
                                        <br></br>
                                        <span className="user-location">
                                            New York, NY
                                        </span>
                                    </p>
                                    <i
                                        className="fa-solid fa-building"
                                        aria-hidden="true"
                                    ></i>
                                    <a
                                        className="user-company"
                                        href="https://google.com"
                                    >
                                        {user.company || "Google"}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="user-stats">
                            <h2>
                                <strong>Stats</strong>
                                <br></br>
                                {user.scores && user.scores.solutions ? (
                                    <>
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip
                                                    placement="bottom"
                                                    {...props}
                                                >
                                                    {
                                                        levels.solutions(
                                                            user.scores
                                                                .solutions
                                                        )[1]
                                                    }
                                                </Tooltip>
                                            )}
                                        >
                                            <span className="stat-label">
                                                {
                                                    levels.solutions(
                                                        user.scores.solutions
                                                    )[0]
                                                }
                                            </span>
                                        </OverlayTrigger>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: `${Math.ceil(
                                                        (user.scores.solutions /
                                                            levels.solutions(
                                                                user.scores
                                                                    .solutions
                                                            )[2]) *
                                                            100
                                                    )}%`,
                                                }}
                                                aria-valuenow={
                                                    user.scores.solutions
                                                }
                                                aria-valuemin="0"
                                                aria-valuemax={
                                                    levels.solutions(
                                                        user.scores.solutions
                                                    )[2]
                                                }
                                            >
                                                {`${Math.ceil(
                                                    (user.scores.solutions /
                                                        levels.solutions(
                                                            user.scores
                                                                .solutions
                                                        )[2]) *
                                                        100
                                                )}%`}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {user.scores && user.scores.problems ? (
                                    <>
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip
                                                    placement="top"
                                                    {...props}
                                                >
                                                    {
                                                        levels.problems(
                                                            user.scores.problems
                                                        )[1]
                                                    }
                                                </Tooltip>
                                            )}
                                        >
                                            <label className="stat-label">
                                                {
                                                    levels.problems(
                                                        user.scores.problems
                                                    )[0]
                                                }
                                            </label>
                                        </OverlayTrigger>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: `${Math.ceil(
                                                        (user.scores.problems /
                                                            levels.problems(
                                                                user.scores
                                                                    .problems
                                                            )[2]) *
                                                            100
                                                    )}%`,
                                                }}
                                                aria-valuenow={
                                                    user.scores.problems
                                                }
                                                aria-valuemin="0"
                                                aria-valuemax={
                                                    levels.problems(
                                                        user.scores.problems
                                                    )[2]
                                                }
                                            >
                                                {`${Math.ceil(
                                                    (user.scores?.problems /
                                                        levels.problems(
                                                            user.scores?.problems || 1
                                                        )[2]) *
                                                        100
                                                )}%`}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <label className="stat-label">Comments</label>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: "70%" }}
                                        aria-valuenow="70"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        70%
                                    </div>
                                </div>

                                <Radar
                                    width={500}
                                    height={500}
                                    padding={70}
                                    domainMax={100}
                                    highlighted={null}
                                    onHover={(point) => {
                                        if (point) {
                                            // console.log("hovered over a data point", point);
                                            // $('[data-toggle="tooltip"]').tooltip("show");
                                        }
                                    }}
                                    data={{
                                        variables: [
                                            {
                                                key: "comments",
                                                label: "Comments",
                                            },
                                            {
                                                key: "problems",
                                                label: "Problems",
                                            },
                                            {
                                                key: "solutions",
                                                label: "Solutions",
                                            },
                                        ],
                                        sets: [
                                            {
                                                key: "me",
                                                label: "My Scores",
                                                values: {
                                                    comments: 40,
                                                    problems:
                                                        (user.scores?.problems || 0 /
                                                            levels.problems(
                                                                user.scores
                                                                    ?.problems || 1
                                                            )[2]) *
                                                        100,
                                                    solutions:
                                                        (user.scores?.solutions /
                                                            levels.solutions(
                                                                user.scores
                                                                    ?.solutions || 1
                                                            )[2]) *
                                                        100,
                                                },
                                            },
                                        ],
                                    }}
                                />
                                <i
                                    id="radar-tooltip"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Tooltip for image"
                                    data-animation="false"
                                    data-trigger="manual"
                                />
                            </h2>
                        </div>
                    </div>
                    <div className="right-user-rail">
                        <div className="user-description">
                            <h2>About</h2>
                            {user.description}

                            <br></br>
                            <br></br>
                            <br></br>
                            <h2>Activity</h2>
                            <br></br>
                            <CalendarHeatmap
                                startDate={shiftDate(today, -360)}
                                endDate={today}
                                values={user ? user.contributions || [] : []}
                                classForValue={(value) => {
                                    if (!value) {
                                        return "color-empty";
                                    }
                                    return `color-github-${value.count}`;
                                }}
                                tooltipDataAttrs={(value) => {
                                    if (!value || !value.date) return "";
                                    return {
                                        "data-tip": `${
                                            value.count || "no"
                                        } contributions on ${
                                            new Date(value.date).toLocaleString(
                                                "default",
                                                {
                                                    month: "long",
                                                }
                                            ) +
                                            " " +
                                            new Date(value.date).getDate()
                                        }`,
                                    };
                                }}
                                showWeekdayLabels={true}
                            />
                            <ReactTooltip />
                        </div>
                        <br></br>
                        <div className="user-experience">
                            <h2>Experience</h2>
                            <br></br>
                            <div className="experience-cards">
                                <div className="experience-card row">
                                    <div className="col-md-3">
                                        <img
                                            className="experience-card-img"
                                            src="https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png"
                                        ></img>
                                    </div>
                                    <div className="col-md-9">
                                        <h5>
                                            <strong>Software Engineer</strong>
                                        </h5>
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit.
                                            Maecenas auctor lacinia felis, ac
                                            ornare mi iaculis et. Nulla ut
                                            accumsan elit, sit amet rutrum ante.
                                            Donec ullamcorper quam malesuada,
                                            laoreet ipsum at, volutpat elit. Sed
                                            in urna in lectus bibendum iaculis.
                                            Etiam massa diam, dapibus sit amet
                                            massa vel, vehicula gravida elit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="user-experience">
                            <h2>Contributions</h2>
                            <br></br>
                            <div className="experience-cards">
                                <div className="experience-card row">
                                    <div className="col-md-3">
                                        <img
                                            className="experience-card-img"
                                            src="https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png"
                                        ></img>
                                    </div>
                                    <div className="col-md-9">
                                        <h5>
                                            <strong>Opensearch</strong>
                                        </h5>
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit.
                                            Maecenas auctor lacinia felis, ac
                                            ornare mi iaculis et. Nulla ut
                                            accumsan elit, sit amet rutrum ante.
                                            Donec ullamcorper quam malesuada,
                                            laoreet ipsum at, volutpat elit. Sed
                                            in urna in lectus bibendum iaculis.
                                            Etiam massa diam, dapibus sit amet
                                            massa vel, vehicula gravida elit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

function shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
}

export default User;
