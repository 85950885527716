import "./Problem.css";
import { Link } from "react-router-dom";
import UserCard from "../../UserCard/UserCard";

function Problem(props) {
    const handleLike = (value) => {
        console.log(props.post);
        if (!props.user) return;
        fetch(`${process.env.BACKEND_URL || "http://"}/likes/like`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            // mode: "cors",
            body: JSON.stringify({
                matchID: props.post._id,
                type: "post",
                value,
                username: props.user ? props.user.username : "",
            }),
        }).then(() => props.fetchPosts());
    };
    return (
        <div id="Problem" className="p-3">
            <div id="problem-content" className="row">
                <div className="problem-likes">
                    <div id="button-box">
                        <div>
                            <i
                                className={`fa-solid fa-chevron-up${
                                    props.post.likeByUser &&
                                    props.post.likeByUser.length > 0 &&
                                    props.post.likeByUser[0].value === 1
                                        ? " liked"
                                        : ""
                                }`}
                                onClick={() => handleLike(1)}
                            ></i>
                        </div>
                        <span className="likes">{props.post.likes}</span>
                        <div>
                            <i
                                className={`fa-solid fa-chevron-down${
                                    props.post.likeByUser &&
                                    props.post.likeByUser.length > 0 &&
                                    props.post.likeByUser[0].value === -1
                                        ? " liked"
                                        : ""
                                }`}
                                onClick={() => handleLike(-1)}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="card-holder">
                    <div>
                        <UserCard
                            img={
                                props.post?.author?.photo ||
                                "https://media.istockphoto.com/id/1311084168/photo/overjoyed-pretty-asian-woman-look-at-camera-with-sincere-laughter.jpg?s=1024x1024&w=is&k=20&c=QMQ46nig58psiPNy6ruC8FFSkF-jUSlh17vEOfMLbgI="
                            }
                            headline={
                                props.post?.author?.headline ||
                                "Data Scientist @ Amazon"
                            }
                            username={props.post?.author?.username}
                            company={props.post?.author?.company}
                        />
                        <div className="badge-box">
                            <span className="post-solution-badge">
                                {props.post.solutions} Solutions
                            </span>
                            {props.post.topics && props.post.topics.length ? (
                                props.post.topics
                                    .filter((topic) => topic != null)
                                    .map((topic) => (
                                        <Link to={`/${topic}`}>
                                            <span className="post-industry-badge">
                                                {topic}
                                            </span>
                                        </Link>
                                    ))
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <p className="lead">
                        <Link
                            to={`/post/${props.post._id}`}
                            className="title"
                        >
                            {props.post.title && props.post.title.length > 60
                                ? props.post.title.substring(0, 60) + "..."
                                : props.post.title}
                        </Link>{" "}
                    </p>
                    <hr></hr>
                    <div className="post-content">
                        <div className="post-overlay"></div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: props.post.content,
                            }}
                        ></p>
                    </div>
                    <div id="problem-footer"></div>
                </div>
            </div>
        </div>
    );
}

export default Problem;
