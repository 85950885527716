import "./UserCard.css";
import React from "react";
import { Link } from "react-router-dom";

const UserCard = (props) => {
  return (
    <div className="user-card d-inline-block">
      <Link to={`/user/${props.username}`}>
        <img className="img-fluid user-card-photo" src={props.img}></img>
      </Link>
      <div className="d-inline-block user-card-content">
        <Link className="user-card-a" to={`/user/${props.username}`}>
          <strong className="user-card-link">{props.username}</strong>
        </Link>
        {/* <span className="badge-trailblazer-bg-less">Trailblazer</span> */}
        {props.company ? (
          <span className="rep-title">{props.company}</span>
        ) : (
          <></>
        )}
        <br></br>
        <span className="user-card-info">
          {props.headline}
          <br></br>
          {/* 10h */}
        </span>
      </div>
    </div>
  );
};

export default UserCard;
